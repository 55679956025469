import { createStore } from 'vuex'

export default createStore({
  state: {
    apiUrl : "https://api.safegrp.com/api",
    assetsUrl : "https://api.safegrp.com/",
    // apiUrl : "http://localhost:8080/api",
    // assetsUrl : "http://localhost:8080/",
    notice : {
      msg : null,
      error : false
    }
  },
  mutations: {
    closeNotice(state) {
      state.notice.msg = null;
      state.notice.error = null;
    },
    setNotice(state, notice) {
      state.notice.msg = notice.msg;
      state.notice.error = notice.error;
    }
  },
  actions: {
  },
  modules: {
  }
})
