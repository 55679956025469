import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import( '../views/Home.vue')
  },
  {
    path: '/password-lost',
    name: 'PasswordLost',
    component: () => import( '../views/PasswordLost.vue')
  },
  {
    path: '/password-reset/:id/:token',
    name: 'PasswordReset',
    component: () => import( '../views/PasswordReset.vue')
  },
  {
    path: '/news',
    name: 'News',
    component: () => import( '../views/News.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/events',
    name: 'Events',
    component: () => import( '../views/Events.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/press-release',
    name: 'PressRelease',
    component: () => import( '../views/PressRelease.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/press-review',
    name: 'PressReview',
    component: () => import( '../views/PressReview.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/career',
    name: 'Career',
    component: () => import( '../views/Career.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/documents',
    name: 'Documents',
    component: () => import( '../views/Documents.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/shareholders-meetings',
    name: 'ShareholdersMeeting',
    component: () => import( '../views/ShareholdersMeeting.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/calendar',
    name: 'Calendar',
    component: () => import( '../views/Calendar.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/add/:cat',
    name: 'AddForm',
    component: () => import( '../views/AddForm.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/update/:post',
    name: 'UpdateForm',
    component: () => import( '../views/UpdateForm.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/add-calendar',
    name: 'AddCalendar',
    component: () => import( '../views/AddCalendar.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/update-calendar/:event',
    name: 'UpdateCalendar',
    component: () => import( '../views/UpdateCalendar.vue'),
    meta: {
      requiresAuth: true
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    if (to.hash) {

      return { el: to.hash, behavior: 'smooth' }
    }
    return { top: 0 }
  }
})

/* verification qu'on est connecté sinon on redirige */
router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if(localStorage._safeToken ) {
      next()
      return
    } 
    next('/')
  }else{
    next() 
  }  
})

export default router
