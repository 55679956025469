<template>
    <Transition>
        <div :class="['notice-container', {error : $store.state.notice.error} ]" v-if="$store.state.notice.msg">
            <div class="notice" v-html="$store.state.notice.msg"></div>
        </div>
    </Transition>
</template>

<script>
export default {
    name : "Notices",
    watch : {
        '$store.state.notice.msg' : function() {
            let vm = this;
             setTimeout(function() {vm.$store.commit('closeNotice')} , 3000 );
        }
    }
}
</script>

<style scoped lang="scss">
    .notice-container {
        position:fixed;
        bottom:1%;
        right:0;
        max-width:200px;
        padding:20px;
        background:$mainColor;
        z-index: 99;
    }

    .notice-container.error{
        background:$red;
    }

    .notice {
        color:#fff;
        text-align: right;
    }

    .v-enter-active,
    .v-leave-active {
        transition: right 0.5s ease;
    }

    .v-enter-from,
    .v-leave-to {
        right: -300px;
    }
</style>