<template>
  <header-app v-if="$route.name != 'Home' && $route.name != 'PasswordLost' && $route.name != 'PasswordReset' "></header-app>
  <router-view/>
  <notices></notices>
</template>

<script>
import HeaderApp from "./components/HeaderApp.vue";
import Notices from "./components/Notices.vue";

export default {
  name : "App",
  components : {
    HeaderApp, Notices
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&display=swap');
@font-face {
 font-family: "Forelle";
 src: local("Merienda"), url("./assets/fonts/Forelle/Forelle.ttf") format("truetype"),
}

@font-face {
    font-family: 'OTW';
    src: url('./assets/fonts/OTW.eot');
    src: url('./assets/fonts/OTW.eot?#iefix') format('embedded-opentype'),
         url('./assets/fonts/OTW.woff') format('woff'),
         url('./assets/fonts/OTW.ttf') format('truetype'),
         url('./assets/fonts/OTW.svg#OTW') format('svg');
    font-weight: normal;
    font-style: normal;
}
[class*='icon-']:before{
	display: inline-block;
   font-family: 'OTW';
   font-style: normal;
   font-weight: normal;
   line-height: 1;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale
}
.icon-otw_icon_bookmark:before{content:'\0041';}
.icon-otw_icon_calendar:before{content:'\0042';}
.icon-otw_icon_home:before{content:'\0043';}
.icon-otw_icon_lecturehall:before{content:'\0044';}
.icon-otw_icon_matchmaking:before{content:'\0045';}
.icon-otw_icon_tchat:before{content:'\0046';}
.icon-otw_icon_cellphone:before{content:'\0047';}
.icon-otw_icon_contact_support:before{content:'\0048';}
.icon-otw_icon_email:before{content:'\0049';}
.icon-otw_icon_fax:before{content:'\004a';}
.icon-otw_icon_location:before{content:'\004b';}
.icon-otw_icon_logout:before{content:'\004c';}
.icon-otw_icon_phone:before{content:'\004d';}
.icon-otw_icon_pin:before{content:'\004e';}
.icon-otw_icon_profile:before{content:'\004f';}
.icon-otw_icon_search:before{content:'\0050';}
.icon-otw_icon_settings:before{content:'\0051';}
.icon-otw_icon_web:before{content:'\0052';}
.icon-otw_icon_calendarplus:before{content:'\0053';}
.icon-otw_icon_facebook:before{content:'\0054';}
.icon-otw_icon_instagram:before{content:'\0055';}
.icon-otw_icon_linkedin:before{content:'\0056';}
.icon-otw_icon_pinterest:before{content:'\0057';}
.icon-otw_icon_twitter:before{content:'\0058';}
.icon-otw_icon_close:before{content:'\0059';}
.icon-otw_icon_arrow_left:before{content:'\005a';}
.icon-otw_icon_arrow_right:before{content:'\0061';}
.icon-otw_icon_arrow_close2:before{content:'\0062';}
.icon-otw_icon_youtube:before{content:'\0063';}
.icon-otw_icon_link:before{content:'\0064';}
.icon-otw_icon_video:before{content:'\0065';}
.icon-otw_icon_back:before{content:'\0066';}
.icon-otw_icon_company:before{content:'\0067';}
.icon-otw_icon_eye:before{content:'\0068';}
.icon-otw_icon_edit:before{content:'\0069';}
.icon-otw_icon_speciality:before{content:'\006a';}
.icon-otw_icon_speaker:before{content:'\006b';}
.icon-otw_icon_trash:before{content:'\006c';}
.icon-otw-icon_boothlink:before{content:'\006d';}
.icon-otw_icon_appointment:before{content:'\006e';}
.icon-otw_icon_watch:before{content:'\006f';}
.icon-otw_icon_live:before{content:'\0070';}
.icon-otw_icon_eposter:before{content:'\0071';}
.icon-otw_icon_booth:before{content:'\0072';}
.icon-otw_icon_video2:before{content:'\0073';}
.icon-otw_icon_specialiazeddoc:before{content:'\0074';}
.icon-otw_icon_product_service:before{content:'\0075';}
.icon-otw_icon_secured_room:before{content:'\0076';}
.icon-otw_icon_virtual_lab:before{content:'\0077';}
.icon-otw_icon_contact_form:before{content:'\0078';}
.icon-otw_icon_saledoc:before{content:'\0079';}
.icon-otw_icon_tchat2:before{content:'\007a';}
.icon-otw_icon_booth2:before{content:'\0030';}
.icon-otw_icon_pdf:before{content:'\0031';}
.icon-otw_icon_ppt:before{content:'\0032';}
.icon-otw_icon_word:before{content:'\0033';}
.icon-otw_icon_search2:before{content:'\0034';}
.icon-otw_icon_close2:before{content:'\0035';}
.icon-otw_icon_profil2:before{content:'\0036';}
.icon-otw_icon_reportanabuse:before{content:'\0037';}
.icon-otw_icon_pdfexport:before{content:'\0038';}
.icon-otw_icon_skype:before{content:'\0039';}
.icon-otw_icon_attachment:before{content:'\0021';}
.icon-otw_icon_sendmail:before{content:'\0022';}
.icon-otw_icon_seriousgames:before{content:'\0023';}
.icon-otw_icon_headquarters:before{content:'\0024';}
.icon-otw_icon_price:before{content:'\0025';}
.icon-otw_icon_guest_speaker:before{content:'\0026';}
.icon-otw_icon_conference-tech:before{content:'\0027';}
.icon-otw_icon_lotery:before{content:'\0028';}
.icon-otw-icon_image_gallery:before{content:'\0029';}
.icon-otw_icon_jobs:before{content:'\002a';}
.icon-otw_icon_workshop:before{content:'\002b';}
.icon-otw_icon_book:before{content:'\002c';}
.icon-otw_icon_refresh:before{content:'\002d';}
.icon-otw_icon_partner:before{content:'\002e';}
.icon-otw_icon_information:before{content:'\002f';}
.icon-otw_iconprogram:before{content:'\005b';}
.icon-otw_icon_espace_pdf:before{content:'\005c';}
.icon-otw_icon_go:before{content:'\005d';}
.icon-otw_icon_cancel:before{content:'\005e';}
.icon-otw_icon_calandar1:before{content:'\005f';}
.icon-otw_icon_calandar2:before{content:'\0060';}
.icon-otw_icon_osetrequestst:before{content:'\007b';}
.icon-otw-icon_confirmed:before{content:'\007c';}
.icon-otw_icon_like:before{content:'\007d';}
.icon-otw_icon_bulb:before{content:'\007e';}
.icon-otw_icon_no_entry:before{content:'\003f';}
.icon-otw_icon_reduce:before{content:'\00a7';}
.icon-otw_icon_enlarge:before{content:'\00b5';}
.icon-otw_icon_close3:before{content:'\00b0';}

body {
  padding:0;
  margin: 0;
  background-color: $black;
  font-family: "Comfortaa", cursive;
}

.main.box, .main {
  margin:80px auto;
}

.colored {
  color:$mainColor;
}

/* form */

input, textarea, select {
  font-family: "Comfortaa", cursive;
  color:$mainColor;
  border-radius: 0;
  appearance: none;
  padding:10px;
  font-size:18px;
  background:#000;
  border:1px solid $mainColor;
  font-weight: 300;
}

::placeholder {
  font-family: "Comfortaa", cursive;
  color:$mainColor;
  font-weight: 300;
}

input:focus, textarea:focus {
  outline: none;
  border: 1px solid #fff;
}

.form-group.error input {
    border-color:$red;
}

.form-group {
  width:100%;
}

.error-msg {
    color:$red;
    font-size:15px;
    line-height:1.5em;
    margin-top:2px;
}

.notices {
    padding:10px;
    width:calc(100% - 20px);
    border-radius:10px;
    text-align:center;
    color:$mainColor;
    border:1px solid $mainColor;
    margin-top:20px;
    background: $mainColor;
    color:#fff;
    font-size:15px;
}

.notices.errors {
    color:#fff;
    border-color:$red;
    background:$red;
}

.checkbox-container {
  display:flex;
  align-items: center
}

input[type="checkbox"] {
    width:20px;
    min-width: 20px;
    height:20px;
    background:#fff;
    border:2px solid #fff;
    padding:0;
    margin-right:20px;
    cursor: pointer;
}

input[type="checkbox"]:checked {
    background:$mainColor;
}

.form-group.error input[type="checkbox"] {
    border:2px solid $red;
}

label {
  color:#fff;
  line-height: 1.3em;
  font-weight: 300;
}

button {
    border: none;
    outline:none;
    cursor: pointer;
}


.row {
  display: flex;
  width:100%;
  margin:20px auto;
}

.row.reverse {
  flex-direction: row-reverse;
}

.col-20 {
  width:18%;
  margin:0 1%
}

.col-25 {
  width:23%;
  margin:0 1%
}

.col-33 {
  width:31%;
  margin:0 1%
}

.col-40 {
  width:38%;
  margin:0 1%
}

.col-50 {
  width:48%;
  margin:0 1%;
}

.col-60 {
  width: 58%;
  margin:0 1%;
}

.col-66 {
  width: 64%;
  margin:0 1%;
}

.col-25 {
  width: 23%;
  margin:0 1%
}

.col-75 {
  width: 73%;
  margin:0 1%
}

.col-100 {
  width: 98%;
  margin:0 1%
}

.col-img {
  width:350px;
  min-width:350px;
  margin-left:40px;
}

.col-text {
  width:calc($box - 390px);
  margin: 0;
}

.box {
  width:$box;
  max-width: $max;
  margin:0 auto;
}

a {
  color:$mainColor;
  text-decoration: none;
  transition: 0.5s;
}

a:hover {
  color:#fff;
}

.section-full {
  min-height: 100vh;
  width:100%;
  display: flex;
  align-items: center;
}

.btn {
  text-decoration: none;
  color:#fff;
  background:$mainColor;
  padding:15px 25px;
  font-size:18px;
  display: inline-block;
  transition:0.5s;
  cursor: pointer;
}

.btn:hover {
  background: darken($mainColor, 10);
}

.btn:disabled {
    opacity: 0.5;
    cursor:not-allowed;
}

.btn.btn-second {
  background:#fff;
  color:#333;
}

.btn.btn-second:hover {
  background: darken(#fff, 20);
}

.btn-icon {
  font-size: 22px;
  margin-right:10px;
  top: 2px;
  position: relative;
}

  .back {
    font-size:15px;
    margin-top:40px;
    cursor: pointer;
    transition: 0.5s;
    color:#fff;
  }

  .back:hover {
    color:$mainColor;
  }

/* modal */

.modal {
  position:fixed;
  top:0;
  bottom:0;
  left:0;
  right:0;
  display: flex;
  justify-content: center;
  align-items: center;
  background:rgba(0,0,0,0.95);
  z-index: 99;
}

.modal-close {
  position:absolute;
  top:30px;
  right:30px;
  color:#fff;
  font-size:40px;
  cursor: pointer;
  font-size:30px;
  color:#000;
  cursor: pointer;
  transition:0.5s;
}

.modal-close:hover {
  color:#fff;
}

.modal-container {  
    max-width: 96%;
}

.tox-statusbar__branding {
  display: none;
}

/* TABLES */
table {
  width:100%;
  border-collapse: collapse;
  margin-top:20px;
  max-width: 100%;
  overflow-x: auto;
}

th {
  background-color:$mainColor;
  color:#fff;
  font-size:15px;
  padding:10px;
  text-transform: uppercase;
  font-weight:300;
}

table  tbody tr td {
  background-color:$black;
  padding:10px;
  font-size: 15px;
  color:#fff;
  text-align: center;
  line-height: 20px;
}


table  tbody tr:nth-child(odd) td {
  background-color:#333;
}

/* FORM */

  .type-input {
    height:20px;
    width:20px;
    background:#fff;
    border-radius: 50%;
    cursor: pointer;
    transition: 0.5s;
  }

  .type-input.selected {
    background:$mainColor;
  }

  .type-label {
    margin-top:10px;
    color:#fff;
    text-align: center;
    font-size:15px;
  }

  .type-item {
    width:120px;
    margin:10px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .col-type {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .label {
    font-size:18px;
    color:#fff;
    text-align: center;
    text-transform: uppercase;
    margin-bottom:10px;
  }

  .col-type .row {
    justify-content: center;
  }

  .checkbox-input {
    width:16px;
    height:16px;
    border:2px solid #fff;
    cursor: pointer;
    transition: 0.5s;
    background:#fff;
  }

  .checkbox-input.selected {
    background:$mainColor;
  }

  input.form-control, textarea.form-control, select.form-control {
    background:#fff;
    width:calc(100% - 20px);
    color:$black;
    border:2px solid #fff;
  }

  select.form-control {
    width:calc(100% + 4px);
  }

  input.form-control:focus, textarea.form-control:focus, select.form-control:focus {
    border-color:$mainColor;
    outline: none;
  }

  input.form-control::placeholder, textarea::placeholder {
    color:$black;
  }

  .row.row-more {
    margin-top:40px;
  }

  .row.row-type {
    margin-top:0;
  }

  .row.row-choice {
    margin-top :80px;
  }

  .row-choice .row.row-type {
    display: flex;
    justify-content: center;
  }

  .form-lang {
    padding:20px 0;
    border-bottom: 1px solid $mainColor;
  }

  .img-container {
    margin-top:40px;
  }

  .date-container {
    margin-top:40px;
  }

  .row-btn {
    display: flex;
    justify-content: center;
  }

  .checkbox-input.error {
    border-color: $red;
  }

  .error-center {
    text-align: center;
    margin-top:-20px;
  }

  .editor-border {
    border:1px solid #fff;
  }

  .editor-border.error {
    border-color: $red;
  }

  .img-thumb {
    max-width: 100%;
    width:100%;
  }

  .generate {
    color:#fff;
    width:100%;
    height:200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .img-notices {
    font-size:12px;
    font-style: italic;
    font-weight: 300;
    text-transform: initial;
  }

  .img-container .label {
    margin-bottom:20px;
  }

  .img-content {
    position: relative;
  }

  .del-img {
    position:absolute;
    top:10px;
    right:10px;
    width:30px;
    height:30px;
    background:$black;
    border-radius:50%; 
    cursor: pointer;
    color:#fff;
    font-size:15px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .del-img:hover {
    background: $mainColor;
    color:#000;
  }

  .form-control.img-input, .form-control.date-input {
    width:calc(100% - 24px);
  }

  .pdf-name  {
    color:#fff;
    cursor: pointer;
    transition:0.5s;
  }

  .pdf-name:hover {
    color:$mainColor;
  }

  .pdf-delete {
    cursor: pointer;
    transition:0.5s;
    margin-left:20px;
    font-size:20px;
    color:#fff;
  }

  .pdf-delete:hover {
    color:$mainColor;
  }

  .pdf-viewer {
    display: flex;
    align-items: center;
  }

  .post-btn {
      width:25px;
      height:25px;
      margin:0 5px;
      cursor: pointer;
      font-size: 20px;
      transition: 0.5s;
      color:#fff;
  }

  .post-btn:hover {
      color: $mainColor;
  }

  .actions-container {
      display: flex;
      align-items: center;
      justify-content: center;
      width:100%;
  }
</style>
