<template>
    <header>
        <div class="box">
            <div class="menu">
                <nav>
                    <router-link  :to="{name : 'News'}" class="menu-link">News</router-link>
                    <router-link  :to="{name : 'Events'}" class="menu-link">Events</router-link>
                    <router-link  :to="{name : 'PressRelease'}" class="menu-link">Press<br />release</router-link>
                    <router-link  :to="{name : 'PressReview'}" class="menu-link">Press<br />review</router-link>
                    <router-link  :to="{name : 'Documents'}" class="menu-link">Documentation</router-link>
                    <router-link  :to="{name : 'ShareholdersMeeting'}" class="menu-link">Shareholders<br />Meeting</router-link>
                    <router-link  :to="{name : 'Career'}" class="menu-link">Career</router-link>
                    <router-link  :to="{name : 'Calendar'}" class="menu-link">Calendar</router-link>
                </nav>
            </div>
            <div class="logout icon-otw_icon_logout" @click="logout()">
            </div>
        </div>
    </header>
</template>

<script>
export default {
    name : "HeaderApp",
    methods : {
        logout() {
            localStorage.removeItem("_safeToken");
            this.$router.push({name : "Home"});
        }
    }
}
</script>

<style lang="scss" scoped>
    header {
        width:100%;
        background:$mainColor;
    }
    .box {
        padding:20px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .logout {
        transition:0.5s;
        font-size:30px;
        cursor: pointer;
        color:#fff
    }

    .logout:hover {
        color:#000;
    }

    .menu-link {
        font-size:15px;
        font-weight: 400;
        color:#fff;
        cursor: pointer;
        text-transform: uppercase;
        margin:0 30px;
        text-align: center;
    }

    .menu-link:hover, .router-link-exact-active {
        color:$black;
    }

    nav {
        display: flex;
        align-items: center;
    }
</style>